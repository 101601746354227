import {Endpoint} from '../Endpoint';
import {IOrganization, ISubscriptionPricing} from '../Types';

/**
 * Get current user's organization (if they are an admin)
 */
export const getMyOrganization = () => {
  return Endpoint.get<IOrganization>('/v2-getmyorganization').then((r) => r.data);
};

/**
 * Get subscription pricing
 */
export const getSubscriptionPricing = () => {
  return Endpoint.get<ISubscriptionPricing>('/v2-getsubscriptionpricing').then((r) => r.data);
};

export const previewUpcomingInvoice = (organization: IOrganization) => {
  return Endpoint.post<any>('/v2-previewupcominginvoice', organization).then((r) => r.data);
};

export const resendEmailVerification = () => {
  return Endpoint.post<any>('/v2-resendemailverification').then((r) => r.data);
};
