import {IProduct} from '..';
import {Endpoint} from '../Endpoint';
import {IOrganization, ISubscriptionPricing, IUser, SubscriptionType} from '../Types';

export const findProductComponent = (q: string) => Endpoint.post('/findProductComponent', {q}).then((r) => r.data);

export const getUsers = () => Endpoint.get<IUser[]>('/v2-getusers').then((r) => r.data);

export const getProducts = () => Endpoint.get('/v2-getproducts').then((r) => r.data);

export const getProduct = (productId: string) => Endpoint.post<IProduct>('/v2-getproduct', {productId}).then((r) => r.data);

export const getOrganizations = () => Endpoint.get<IOrganization[]>('/v2-getorganizations').then((r) => r.data);

export const getOrganization = (organizationId: string) =>
  Endpoint.post<IOrganization>('/v2-getorganization', {organizationId}).then((r) => r.data);

export const getOrganizationInvoices = (organizationId: string) =>
  Endpoint.post('/v2-getorganizationinvoices', {organizationId}).then((r) => r.data);

export const getOrganizationProducts = (organizationId: string) =>
  Endpoint.post('/v2-getorganizationproducts', {organizationId}).then((r) => r.data);

interface IAddProductToOrganizationRequest {
  productId: string;
  organizationId: string;
  targetType: string;
}

export const addProductToOrganization = (params: IAddProductToOrganizationRequest) =>
  Endpoint.post('/v2-addproducttoorganization', params).then((r) => r.data);

export const createOrganization = (params: IOrganization) => Endpoint.post('/v2-createorganization', params).then((r) => r.data);

interface ICreateProductRequest {
  name: string;
  phone_number?: string;
  primary_contact?: string;
  primary_email?: string;
  street_address?: string;
}

export const createProduct = (params: ICreateProductRequest) => Endpoint.post('/v2-createproduct', params).then((r) => r.data);

export const deleteUser = (userId: string) => Endpoint.post('/v2-deleteuser', {userId}).then((r) => r.data);

export const deleteOrganization = (organizationId: string) => Endpoint.post('/v2-deleteorganization', {organizationId}).then((r) => r.data);

interface IRemoveProductFromOrganizationRequest {
  productId: string;
  organizationId: string;
}

export const removeProductFromOrganization = (params: IRemoveProductFromOrganizationRequest) =>
  Endpoint.post('/v2-removeproductfromorganization', params).then((r) => r.data);

export const removeExtraSubscriptions = () => Endpoint.post('/v2-removeextrasubscriptions').then((r) => r.data);

interface IResetUserPassworRequest {
  targetType: string;
  targetId: string;
}

export const resetUserPassword = (params: IResetUserPassworRequest) => Endpoint.post('/v2-resetuserpassword', params).then((r) => r.data);

interface IAuthEntry {
  targetType: string;
  targetId: string;
}

interface IUpdateOrganizationRequest {
  organizationId: string;
  id: string;
  name?: string;
  street_address?: string;
  phone_number?: string;
  primary_email?: string;
  user_type?: string;
  primary_contact?: string;
  showProducts?: boolean;
  showCityBoundaries?: boolean;
  initialLat?: number;
  initialLon?: number;
  initialZoom?: number;
  initialAuthorizationId?: string;
  county?: string;
  revenue?: number;
  ein?: number;
  subscription_type?: SubscriptionType;
  addAuths?: IAuthEntry[];
  removeAuths?: IAuthEntry[];
}

export const updateOrganization = (params: IUpdateOrganizationRequest) =>
  Endpoint.post('/v2-updateorganization', params).then((r) => r.data);

export const updateAdminOrganization = (params: IUpdateOrganizationRequest) =>
  Endpoint.post('/v2-updateadminorganization', params).then((r) => r.data);

interface IUpdateProductRequest {
  productId: string;
  name?: string;
  initialLat?: number;
  initialLon?: number;
  initialZoom?: number;

  addAuths?: IAuthEntry[];
  removeAuths?: IAuthEntry[];
}

export const updateProduct = (params: IUpdateProductRequest) => Endpoint.post('/v2-updateproduct', params).then((r) => r.data);

interface IUpdateUserRequest {
  userId: string;
  organizationId?: string;
  user_status?: string;
  firstName?: string;
  lastName?: string;
  user_type?: string;
  phone_number?: string;
  initialLat?: number;
  initialLon?: number;
  limitedView?: boolean;
}

export const updateUser = (params: IUpdateUserRequest) => Endpoint.post('/v2-admin/updateuser', params).then((r) => r.data);

export const updatePrices = (params: ISubscriptionPricing['subscriptionTypes']) =>
  Endpoint.post('/v2-updateprices', params).then((r) => r.data);

export const createTrialSubscription = (params: {organization: IOrganization; periodEnd?: number}) =>
  Endpoint.post('/v2-createtrialsubscription', params).then((r) => r.data);

export const updateExistingOrganizations = () => Endpoint.post('/v2-updateexistingorganizations').then((r) => r.data);

export const getUserDetails = (userId: string) => Endpoint.get(`/v2-getuserdetails/${userId}`).then((r) => r.data);
