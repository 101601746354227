import {Endpoint, AuthEndpoint} from '../Endpoint';
import {IUserProfile} from '../Types';
import authConfig from '../../auth_config.json';

// Log in and create a session
export interface ILoginRequest {
  email: string;
  password: string;
}

export interface ILoginResponse {
  // The access token is what we would use to talk to Auth0. We never actually do this right now, but we could direct-wire some calls
  // like "change my password" or user profile updates if we wanted to. For reference, the default "scope" of this token in Auth0's terms
  // is:
  //
  //     openid email profile read:current_user update:current_user_metadata delete:current_user_metadata create:current_user_metadata
  //     create:current_user_device_credentials delete:current_user_device_credentials update:current_user_identities offline_access
  //
  // those are the operations the user could directly perform with this access token by calling Auth0 directly (if we wanted).
  access_token: string;

  // This decodes to an IDecodedIdToken (see above). It should be an HS256 signed token that can be verified via the client_secret of the
  // app in Auth0.

  // The id token is what we use to talk to our own server. We use this because it includes the email claim:
  // https://auth0.com/docs/configure/apis/scopes/sample-use-cases-scopes-and-claims#add-custom-claims-to-a-token
  id_token: string;

  // How long (seconds) before this token expires.
  expires_in: 86400;

  profile: IUserProfile;
}

export const authenticate = (params: ILoginRequest) => Endpoint.post<ILoginResponse>('/v2-authenticate', params).then((r) => r.data);

export const getMyProfile = () => Endpoint.get<IUserProfile>('/v2-getmyprofile').then((r) => r.data);

interface ICreateUserRequest {
  email: string;
  password: string;
}

export const createUser = (params: ICreateUserRequest) => Endpoint.post<ILoginResponse>('/v2-createuser', params).then((r) => r.data);

interface IRequestPasswordResetRequest {
  email: string;
}

export const requestPasswordReset = (params: IRequestPasswordResetRequest) =>
  AuthEndpoint.post('/v2-dbconnections/change_password', {
    client_id: authConfig.clientId,
    connection: 'Username-Password-Authentication',
    ...params,
  }).then((r) => r.data);

interface IProfileUpdateRequest {
  city?: string;
  email?: string;
  orgName?: string;
  firstName?: string;
  lastName?: string;
  phone_number?: string;
  zip?: string;
  user_options?: string;
  onboarding_completed?: string;
}

export const updateMyProfile = (params: IProfileUpdateRequest) =>
  Endpoint.post<IUserProfile>('/v2-updatemyprofile', params).then((r) => r.data);

export interface IMailchimp {
  listId?: string;
  firstName?: string;
  lastName?: string;
}

export const addToMailchimpList = (params?: IMailchimp) => Endpoint.post('/v2-addtomailchimplist', params).then((r) => r.data);

export interface ISignupEmail {
  firstName: string;
  lastName: string;
  phone_number: string;
  zip: string;
}

export const sendSignupEmail = (params: ISignupEmail) => Endpoint.post('/v2-sendsignupemail', params).then((r) => r.data);

export const getMySubscriptionStatus = () => Endpoint.get('/v2-getmysubscriptionstatus').then((r) => r.data);
