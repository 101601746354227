const mixpanelToken = 'd1d84ad67efe83c86012f4fb389e4136';
const devMixpanelToken = '9171cfa59f1a7bbc4e3edc5ee36520f2';
const stripePublishableKey = 'pk_test_51JVeKCDRkkOcBOv7kqQ82DRLrHbsIwucg30BVLXAVaeLTbhajNzi13yTBw4rtCrPkrHM2SxhuWAn76KeawWZRIAz00NVtwV02D'; // greenlink
// const stripePublishableKey = 'pk_test_51N0AexG7MXzuJh0u4JAbOFFCOfprrVONotcibIzVrhdIbpGyWkWbBe3HnzPZ2DPsTPIIRnxoHAPoNe7gTQVZhHXn00i6cPAJFm'; // clark
const auth0RolesNamespace = 'https://equitymap.org/roles';

export const config = {
  local: {
    apiUrl: 'http://localhost:5001/equity-mapping-development/us-central1',
    audience: 'https://gem-develop.equitymap.org/',
    auth0ClientId: 'O69YMflKaXXL9f8oLQ2nU6d84iWjZ2Nh',
    authBaseURL: 'greenlink-develop.us.auth0.com',
    env: 'local',
    stripePublishableKey,
    mixpanel: devMixpanelToken,
    auth0RolesNamespace,
  },
  dev: {
    apiUrl: 'https://us-central1-equity-mapping-development.cloudfunctions.net',
    audience: 'https://gem-develop.equitymap.org/',
    auth0ClientId: 'O69YMflKaXXL9f8oLQ2nU6d84iWjZ2Nh',
    authBaseURL: 'greenlink-develop.us.auth0.com',
    env: 'dev',
    stripePublishableKey,
    mixpanel: devMixpanelToken,
    auth0RolesNamespace,
  },
  staging: {
    apiUrl: 'https://us-central1-equity-mapping-staging.cloudfunctions.net',
    audience: 'https://gem-staging.equitymap.org/',
    auth0ClientId: 'opjF8h445DSNiwxHylrW4mln2JkctC9c',
    authBaseURL: 'https://dev-zvfen1l9.us.auth0.com',
    env: 'staging',
    stripePublishableKey,
    mixpanel: mixpanelToken,
    auth0RolesNamespace,
  },
  production: {
    apiUrl: 'https://us-central1-voltaic-phalanx-276320.cloudfunctions.net',
    audience: 'https://gem.equitymap.org/',
    auth0ClientId: 'wIOEXdpgaUSNFcki7PWlon3d3oS80N93',
    authBaseURL: 'https://greenlink-prod.us.auth0.com',
    env: 'production',
    stripePublishableKey: 'pk_live_51JVeKCDRkkOcBOv7CArshYXHZDOUEi2e845i05025wEE8jw5CzSrkFkWyT2NWxXqb11Tpv6EOoyztu6cv2JoDaeg00m9LB8yIk',
    mixpanel: mixpanelToken,
    auth0RolesNamespace,
  },
};
